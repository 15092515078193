import * as React from 'react';
import Grid from '@mui/material/Grid';
import TutorCard from './TutorCard';
import Typography from '@mui/material/Typography';

function TutorsList({ tutors }) {
  return (
    <Grid container spacing={4} sx={{}}>
      <Grid item xs={12}><Typography className='font-main' variant='h4' sx={{fontWeight: 'bold', color: '#214cce' }}>Meet the Tutors!</Typography></Grid>
      {tutors.map(tutor => (
        <Grid item xs={12} sm={4} md={4} key={tutor.name}>
          <TutorCard tutor={tutor} />
        </Grid>
      ))}
    </Grid>
  );
}

export default TutorsList;
