import * as React from 'react';
import Box from '@mui/system/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Modal from '@mui/material/Modal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function TutorCard({ tutor }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 5,
    width: '90%', // Set the width to 85% of the viewport
    maxHeight: '90vh', // Maximum height to prevent overflow off-screen
    maxWidth: '650px', // Set the maximum width for larger screens
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    boxSizing: 'border-box', // Ensure padding doesn't add to the width
    overflowY: 'auto', // Enable vertical scrolling
  };
  

  return (
    <>
      <Card sx={{ width: 'auto', borderRadius: '20px', boxShadow: 3, position: 'relative', p: 0, m: 0}}>
        <CardMedia
          component="img"
          height="275"
          image={tutor.imgSrc}
          alt={tutor.name}
        />
        <CardContent>
          <Typography className="font-main" sx={{color: '#214cce', textAlign: 'center'}} variant="h6" component="div">
            {tutor.name}
          </Typography>
          <Typography className="font-main"  variant="body2" color="text.secondary" sx={{textAlign: 'center'}}>
            {tutor.bio}
          </Typography>
        </CardContent>
        <IconButton 
          onClick={handleOpen} 
          sx={{ position: 'absolute', top: '5px', right: '5px', backgroundColor: 'white', color: '#214cce' }}
        >
          <AddCircleOutlineIcon  />
        </IconButton>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="tutor-modal-title"
        aria-describedby="tutor-modal-description"
      >
        <Card sx={style}>
          <Typography id="tutor-modal-title" sx={{color: '#214cce'}} variant="h6" component="h2">
            {tutor.name}
          </Typography>
          <Typography id="tutor-modal-description" sx={{ mt: 2 }}>
            {tutor.extendedBio}
          </Typography>
        </Card>
      </Modal>
    </>
  );
}

export default TutorCard;
