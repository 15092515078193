import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/Star';
import Carousel from 'react-material-ui-carousel';
import useMediaQuery from '@mui/material/useMediaQuery';

const reviews = [
  {
    name: 'Bhumika Kapadia-Shah',
    stars: 5,
    quote: "Highly recommend! Paras guided our son through his AP courses, helping him achieve top scores. He truly understands each student's needs and adapts his teaching methods to ensure success.",
  },
  {
    name: 'Sharjeel Sajid',
    stars: 5,
    quote: 'Paras provided excellent guidance and support, helping my child soar academically. She even got into her top choice school after working with Paras throughout the entire college application process!',
  }, 
  {
    name: 'Maunika Shah',
    stars: 5,
    quote: 'Highly recommend Eleni! She is exceptionally professional and talented, helping my son achieve top scores on both the SAT and ACT.',
  }
];

function ReviewCarousel() {
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isMediumScreen = useMediaQuery('(min-width:600px)');

    let itemsPerPage = 1;
    if (isLargeScreen) {
        itemsPerPage = 3;
    } else if (isMediumScreen) {
        itemsPerPage = 2;
    }

    const settings = {
      navButtonsAlwaysVisible: true,
      animation: "slide", // Sliding animation
      indicators: false,
      autoPlay: false,
      cycleNavigation: false, 
      interval: 500, // Slows down the animation speed (500ms)
      duration: 800, // Slow down the slide transition to 800ms
      navButtonsProps: {
          style: {
              backgroundColor: 'black',
              color: 'white'
          }
      }
  };
  
const groupedReviews = [];
for (let i = 0; i < reviews.length; i += itemsPerPage) {
    let j = i;  // 0
    const group = [];
    while (j < reviews.length && group.length < itemsPerPage) {
      group.push(reviews[j]);
      j++;
    }
    groupedReviews.push(group);
}

    return (
      <Grid>
        <Grid item xs={12}><Typography className='font-main' variant='h4' sx={{fontWeight: 'bold', color: '#000' }}>Testimonials</Typography></Grid>
        <Carousel {...settings}>
            {groupedReviews.map((group, index) => (
                <Grid container spacing={2} key={index}>
                    {group.map((review, idx) => (
                        <Grid item xs={12} sm={6} md={4} key={idx}>
                            <Card>
                                <CardContent style={{minHeight: '300px', maxHeight: '300px'}}>
                                    <Box display="flex" alignItems="center">
                                        {[...Array(review.stars)].map((_, i) => (
                                            <StarIcon key={i} style={{color: "#FFDF00"}}/>
                                        ))}
                                    </Box>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {review.name}
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        {review.quote}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </Carousel>
      </Grid>
    );
}

export default ReviewCarousel;
