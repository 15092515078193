import React from 'react';
import { Box, Typography, Container } from '@mui/material';
// import Logo from './Logo'; 

const Footer = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: '#fff', width: '100%', py: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'right' }}>
            <img src='/imgs/logo.png' alt="Logo" style={{ width: '50%', minWidth: '100px', height: 'auto' }} />
          </Box>
          <Box sx={{ flex: 1, pl: 5 }}>
            <Typography sx={{typography: { xs: 'h4', md: 'h2' }, fontWeight: 'bold', color: '#214cce' }}>Contact Us</Typography>
            <Typography variant="body1">Email: <a href="mailto:paras@onivyway.com">paras@onivyway.com</a></Typography>
            <Typography variant="body1">Phone: <a href="tel:+19739026939"> +1 973-902-6939 </a></Typography>
          </Box>
        </Box>
    </Box>
  );
};

export default Footer;
