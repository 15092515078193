import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import BookIcon from '@mui/icons-material/Book';

export default function About() {
  return (
    <Box sx={{ position: 'relative' }}>
      <Typography className="font-main" variant="h4" gutterBottom sx={{ fontWeight: 'bold'}}>What We Offer</Typography>
      <Typography className="font-main" variant="body1">
        Over the past 8 years, we've worked with over 80 students—from grade school to university—across nearly all subjects. 
        Our educators grow meaningful relationships with students, motivating them to achieve new heights both in and outside of the classroom.
      </Typography>
      <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", my: 3 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, color: '#214cce', height: '100%' }}>
            <SchoolIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box>
              <Typography variant="h6">Test Prep</Typography>
              <Typography variant="body1" sx={{color: 'black'}}>Whether it's the SAT, ACT, APs, ISEE, or MCAT you're looking to improve on, 
              our exam-acing tutors have got you covered! Within 3 months, most students achieve their goal scores — so contact us today to get started!  </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, color: '#214cce', height: '100%' }}>
            <AccountCircleIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box>
              <Typography variant="h6">College Counselling</Typography>
              <Typography variant="body1" sx={{color: 'black'}}>Get resume-building consulting & essay help from past students who themselves have successfully navigated the ultra-competitive college application process. 
              Our mentors will help ideate & revise college essays as well as help plan your student's high school trajectory to give them the greatest shot at success. </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, color: '#214cce', height: '100%' }}>
            <BookIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box>
              <Typography variant="h6">Coursework Help</Typography>
              <Typography variant="body1" sx={{color: 'black'}}>Our tutors come from a wide breadth of backgrounds, so we've got you covered for any subject at any grade level! Our tutors teach and re-inforce concepts through practice problems.
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, color: '#214cce', height: '100%' }}>
            <HelpIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box>
              <Typography variant="h6">And More!</Typography>
              <Typography variant="body1" sx={{color: 'black'}}>Whatever else you and your student might need support with, our tutors are here for you!</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
