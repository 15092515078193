import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import About from './components/About.js';
import ContactForm from './components/ContactForm.js';
import TutorsList from './components/TutorsList.js';
import Footer from './components/Footer.js'
import ReviewCarousel from './components/ReviewCarousel.js';

import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import { Link } from 'react-scroll';

import './App.css';
import { Typography } from '@mui/material';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['About', 'Tutors', 'Contact'];

function Header(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const sections = {
    About: 'about-section',
    Tutors: 'tutors-section',
    Contact: 'contact-section',
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', color: '#214cce' }}>
      <Box sx={{my: 2}}>
        <img src='/imgs/logo.png' alt="Logo" style={{ width: '60%', height: 'auto' }} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link
          key={item}
          to={sections[item]}
          spy={true}
          smooth={true}
          duration={500}
          offset={-70} // Adjust based on your header height
          onClick={handleDrawerToggle}
          >
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ }}>
      <CssBaseline />
      <AppBar component="nav" sx={{backgroundColor: 'white', color: '#214cce', position: 'fixed'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, my: 1, display: { xs: 'none', sm: 'block' }}}>
            <img src='/imgs/logo.png' alt="Logo" style={{ width: '120px', height: 'auto' }} />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Link
              key={item}
              to={sections[item]}
              spy={true}
              smooth={true}
              duration={500}
              offset={-70} // Adjust based on your header height
              >
              <Button key={item} sx={{ fontSize: '1.2rem' }}>
                {item}
              </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{paddingTop: {xs: '50px', sm: '88px'}}}>
      <img src="/imgs/banner.png" alt="Banner" style={{ width: '100%'}} />
      </Box>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

function App() {
  const tutors = [{name: "Paras Nahar", bio: "UPenn Alum | Founder ", imgSrc: "/imgs/paras.png", 
                  extendedBio: (
                    <>
                      <p>
                        Paras holds a <strong>bachelor's degree in Computer Science from the University of Pennsylvania</strong>, where he graduated in 2024, and is currently a <strong>software engineer at Susquehanna International Group (SIG)</strong>, a leading quantitative trading fund. He has been tutoring for the past 8 years and has helped over 80 students achieve their academic goals! His achievements include:
                      </p>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Graduating <strong>summa cum laude from UPenn</strong> </li>
                        <li>Scoring a <strong>1570/1600</strong> on the SAT</li>
                        <li>Scoring a <strong>5 on nearly all AP exams</strong>, including AP Calculus AB, AP Calculus BC, AP English Language & Composition, AP Literature, and AP Macroeconomics exams. </li>
                        <li>Being selected as a <strong>National Merit Scholar</strong> - an honor only 2500 of 1.4 million PSAT testers receive each year</li>
                      </ul>
                    </>
                  )},
                  {name: "Eleni Pisinos", bio: "UPenn Alum | Pre-Law", imgSrc: "/imgs/eleni.png", extendedBio: (
                    <>
                      <p>
                      Eleni recently graduated from the <strong>University of Pennsylvania with Honors in Political Science and Economics</strong> and served as an active officer of the <strong>Class of 2024’s Class Board</strong>. An effective communicator, Eleni has been tutoring for the past 2 years and has discovered a deep passion for it. Her achievements include:
                      </p>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Graduating <strong>honors from UPenn</strong> in Poli Sci and Economics</li>
                        <li>Working full-time as <strong>paralegal at Davis & Polk</strong>, a top corporate law firm, and <strong>interning at Morgan Stanley</strong> the year prior</li>
                        <li>Serving a 4-year term as <strong>UPenn's Chair of the College of Arts and Sciences</strong> for the Class of 2024 </li>
                        <li>Scoring a <strong>35/36</strong> on the ACT</li>
                        <li>Scoring a <strong>5 on nearly all her AP exams</strong></li>
                      </ul>
                    </>
                  )},
                  {
                    name: "Brooke Giddon",
                    bio: "UPenn Alum | Anthropology, French, English",
                    imgSrc: "/imgs/brooke.png", 
                    extendedBio: (
                      <>
                        <p>
                          Brooke graduated from the <strong>University of Pennsylvania</strong> in 2024 with a bachelor's degree in
                          <strong> Cultural and Linguistic Anthropology</strong>. She also completed three minors in <strong>English</strong>, 
                          <strong> French & Francophone Studies</strong>, and <strong>Global Medieval & Renaissance Studies</strong>. Brooke 
                          thrives as a humanities researcher and writer, skills she plans to use as a Professor of Cultural History. Her 
                          future in academia and experience tutoring make her well-suited to mentor younger students. Some of her achievements 
                          include:
                        </p>
                        <ul style={{ marginLeft: '20px' }}>
                          <li>Graduating <strong>Summa Cum Laude with honors with a 3.99 GPA</strong> from UPenn</li>
                          <li>Working as a full-time paralegal in NY at top law firm <strong>Davis & Polk</strong></li>
                          <li>Member of the <strong>Phi Beta Kappa</strong> academic honor society and <strong>Pi Delta Phi</strong> French honor society</li>
                          <li>Scoring a <strong>1540 on the SAT</strong> (Reading and Writing 790, Math 750)</li>
                          <li>Serving as <strong>Undergraduate Fellow in Global Medieval & Renaissance Studies</strong> at UPenn</li>
                          <li>Achieving <strong>fluency in French</strong> language</li>
                          <li>Completing an academic semester abroad in Paris, France at <strong>Columbia University</strong> and Sorbonne University</li>
                        </ul>
                      </>
                    )
                  },
                  {name: "Roland Zhang", bio: "UPenn Alum | Pre-Med", imgSrc: "/imgs/roland.png",   extendedBio: (
                    <>
                      <p>
                        Roland graduated the <strong>University of Pennsylvania in 2024 with a bachelor’s degree in Neuroscience.</strong> On a path to medicine, with years of experience in biomedical research and clinical interactions, he excels in teaching the natural sciences.  He has tutored for over four years, consistently translating the same strategies that led to his own success to his students. Some of his achievements include:</p>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Graduating honors with a <strong>4.0 GPA from UPenn</strong> </li>
                        <li>Scoring a <strong>520/528 on the MCAT</strong>, placing him in the top 3% of all test takers</li>
                        <li>Scoring a <strong>perfect 36/36</strong> on the ACT</li>
                        <li>Scoring a <strong>5 on all his AP exams</strong>, including AP Calculus AB, AP Calculus BC, APUSH, AP English Language & Composition, and more. </li>
                        <li>Being selected as a <strong>National Merit Scholar</strong> - an honor only 2500 of 1.4 million PSAT testers receive each year</li>
                      </ul>
                    </>
                  )},
                  {name: "Linda Wu", bio: "UPenn Senior | Wharton & Bio", imgSrc: "/imgs/linda.png", extendedBio: (
                    <>
                      <p>
                      Linda is a senior pursuing two separate bachelor's degrees in Biology and Economics from the <strong>Wharton School at the University of Pennsylvania</strong>. She has been <strong>tutoring for the past 6 years</strong> in subjects ranging from AP Calculus BC to SAT to AP Biology. Her achievements include:
                      </p>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Getting into <strong>UPenn's competitive dual degree program</strong> between the College of Arts and Sciences and Wharton</li>
                        <li>Scoring a <strong>1560/1600</strong> on the SAT</li>
                        <li>Being inducted into the National, Math, Science, History, and English Honor Societies</li>
                      </ul>
                    </>
                  )},
                  {name: "Yeo-Re Lee", bio: "UPenn Senior | Wharton & Comp Sci", imgSrc: "/imgs/yeore.png",   extendedBio: (
                    <>
                      <p>
                        Yeo-Re Lee is a senior at the <strong>University of Pennsylvania (UPenn)</strong> pursuing a 
                        <strong>bachelor's in Economics from Wharton</strong> and a <strong>master's in Computer Science</strong> 
                        from the School of Engineering and Applied Sciences. After graduation, he plans to join 
                        <strong>Bain & Company</strong> as a management consultant. Yeo-Re's passion for education has 
                        led him to teach in various settings, from 6th-grade math in West Philadelphia to Python programming in Laos. 
                        As a private tutor, he has over <strong>6 years of experience</strong> specializing in SAT and Math subjects. 
                        Some of his achievements include:
                      </p>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Scoring a <strong>1570/1600 on the SAT</strong> </li>
                        <li>Receiving <strong>100% approval rate</strong> as a teaching assistant for Wharton class</li>
                        <li>On track to graduate <strong>Magma Cum Laude</strong> from UPenn</li>
                      </ul>
                    </>
                  )},
                  {name: "Kayana Coradin", bio: "MIT Sophomore | Mechanical Engineering", imgSrc: "/imgs/kayana.png",   extendedBio: (
                    <>
                      <p>
                        Kayana is a sophomore at the <strong>Massachusetts Institute of Technology (MIT) </strong> majoring in <strong>Mechanical Engineering</strong>. She has <strong>four years of tutoring experience</strong> ranging from middle school math and science to SAT prep. Some of her many achievements include:</p>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Brandishing a <strong>5.0 GPA at MIT</strong> </li>
                        <li>Scoring a <strong>1540/1600 on the SAT</strong> with a perfect 800 on Math.</li>
                        <li>Scoring a <strong>5 on all her AP exams</strong>, including AP Calculus AB, AP Calculus BC, AP Biology, AP Physics, AP English Language & Composition, and AP Literature. </li>
                      </ul>
                    </>
                  )}]

  return (
    <Grid container>
      <CssBaseline />
      <Header />
      <Box id="about-section" sx={{px: { xs: 5, md: 10, lg: 20 }, pt: 4, pb: 4}}>
        <About />
      </Box>
      <Box id="tutors-section" sx={{px: { xs: 5, md: 10, lg: 20 }, backgroundColor: '#EEF4F8', pt: 4, pb: 4}}>
        <TutorsList tutors={tutors} />
      </Box>
      <Box id="reviews-section" sx={{px: { xs: 5, md: 10, lg: 20}, pt: 4, pb: 4, width: '100%'}}>
        <ReviewCarousel />
      </Box>
      <Box id="contact-section" sx={{width: '100%'}}>
        <ContactForm />
        <Box sx={{px: { xs: 6, lg: 10 }}}>
          <Footer />
        </Box>
      </Box>
    </Grid>
  );
}

export default App;
