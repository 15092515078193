import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Grid } from '@mui/material';

function ContactForm() {
  const config = require('../config.js');

  // Determine if the port should be included based on the presence of a port number in config
  let apiUrlPrefix;
  if (config.node_env !== 'production') {
      apiUrlPrefix = `${config.server_host}:${config.server_port}`;
  } else {
      apiUrlPrefix = `${config.server_host}`;
  }

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    interest: '',
    message: ''
  });

  const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const isPhone = (phone) => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(phone);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    const newErrors = {};

    if (!formValues.name) {
      newErrors.name = 'Name is required';
    }

    if (!formValues.email) {
      newErrors.email = 'Email is required';
    } 
    else if (!isEmail(formValues.email)) {
      newErrors.email = 'Please provide a valid email';
    }

    if (!formValues.phone) {
      newErrors.phone = 'Phone is required';
    } 
    else if (!isPhone(formValues.phone)) {
      newErrors.phone = 'Please provide a valid phone number';
    }

    if (!formValues.interest) {
      newErrors.interest = 'Interest is required';
    } 

    if (!formValues.message) {
      newErrors.message = 'Message is required';
    } 


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
    else {
      try {
        console.log(formValues);
        const response = await fetch(`${apiUrlPrefix}/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValues),
        });
        const responseData = await response.json();
        if (response.ok) {
          console.log('Email sent successfully');
          alert('Email sent successfully');
        } else {
          throw new Error(responseData.message || 'Failed to send email');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error sending email');
      }
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{ width: '100%', mx: 'auto', px: { md: 3, lg: 10 }, py: 5, backgroundColor: '#214cce', color: 'white' }}
    >
      <Typography variant="h4" className='font-main' gutterBottom sx={{ textAlign: 'center', mb: 3, fontWeight: 'bold' }}>
        Let's Connect!
      </Typography>
      <Grid container spacing={2} sx={{px: { xs: 5, lg: 8 }}}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            fullWidth
            required
            id="name"
            name="name"
            error={!!errors.name}
            helperText={errors.name}
            value={formValues.name}
            InputProps={{ disableUnderline: true,  sx: { borderRadius: 5} }}
            className="inputRounded"
            onChange={handleChange}
            variant="filled"
            sx={{ backgroundColor: 'white', borderRadius: 5, borderColor: 'white'}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="email"
            name="email"
            label="Email"
            type="email"
            error={!!errors.email}
            helperText={errors.email}
            value={formValues.email}
            InputProps={{ disableUnderline: true, sx: { borderRadius: 5, } }}
            onChange={handleChange}
            variant="filled"
            sx={{ backgroundColor: 'white', borderRadius: 5}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="phone"
            name="phone"
            label="Phone"
            type="tel"
            error={!!errors.phone}
            helperText={errors.phone}
            value={formValues.phone}
            InputProps={{ disableUnderline: true, sx: { borderRadius: 5, } }}
            onChange={handleChange}
            variant="filled"
            sx={{ backgroundColor: 'white', borderRadius: 5}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            id="interest"
            name="interest"
            label="I'm Interested In..."
            error={!!errors.interest}
            helperText={errors.interest}
            value={formValues.interest}
            InputProps={{ disableUnderline: true, sx: { borderRadius: 5, } }}
            onChange={handleChange}
            variant="filled"
            sx={{ backgroundColor: 'white', borderRadius: 5}}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="message"
            name="message"
            label="How Can We Help You?"
            multiline
            rows={8}
            value={formValues.message}
            error={!!errors.message}
            helperText={errors.message}
            InputProps={{ disableUnderline: true, sx: { borderRadius: 5 } }}
            onChange={handleChange}
            variant="filled"
            sx={{ backgroundColor: 'white', borderRadius: 5}}
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2, p: 3, borderRadius: 5 }}>
            <Typography variant='h6'>Submit</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactForm;
